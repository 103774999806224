/* eslint-disable no-unused-vars */

import Creative from "../Creative";
import groups from "./groups";
import {
  combo,
  stepWithFallback,
  setCreativesGroup,
  setCreativesHiddenAfterAt,
  setCreativesVisibleAfterAt,
  whenSplit,
  whenSplitInRange,
  getCreativesInRandomOrder,
  skipOnMultifaceError,
  getCreativesInRandomOrderWithWeights
} from "./helpers";
import CreativeConfig from "../CreativeConfig";
import {handlersNames} from "../handlers";

function getGroups() {
  return [
    groups.g1,
  ];
}

const positionsTemplatesPairs = [
  {canvasId: 7827, maskId: 7830, gender: "man and woman"},
  {canvasId: 7913, maskId: 7914, gender: "man and woman"},
  {canvasId: 7909, maskId: 7910, gender: "woman and man"},
];

const promptTemplates = [
  {id: 8095, weight: 3.737211279},
  {id: 8094, weight: 3.480649504},
  {id: 8105, weight: 3.421077028},
  {id: 8146, weight: 3.266244572},
  {id: 8138, weight: 3.182296955},
  {id: 8149, weight: 3.122284219},
  {id: 8096, weight: 3.149795079},
  {id: 8100, weight: 2.98985331},
  {id: 8097, weight: 2.921703668},
  {id: 8122, weight: 2.922583674},
  {id: 8125, weight: 2.821388433},
  {id: 8098, weight: 2.805329847},
  {id: 8117, weight: 2.724195971},
  {id: 8124, weight: 2.759135685},
  {id: 8133, weight: 2.782761591},
  {id: 8120, weight: 2.759700031},
  {id: 8099, weight: 2.67844793},
  {id: 8103, weight: 2.653658645},
  {id: 8137, weight: 2.613189183},
  {id: 8148, weight: 2.584681117},
  {id: 8134, weight: 2.532417472},
  {id: 8121, weight: 2.277644169},
  {id: 8147, weight: 2.277644169},
  {id: 8132, weight: 2.186437787},
  {id: 8131, weight: 2.174971721},
  {id: 8116, weight: 2.156697467},
  {id: 8102, weight: 2.073192858},
  {id: 8145, weight: 2.037621575},
  {id: 8126, weight: 2.051136014},
  {id: 8151, weight: 1.967628813},
  {id: 8123, weight: 1.968309598},
  {id: 8119, weight: 1.965310527},
  {id: 8130, weight: 1.875074806},
  {id: 8142, weight: 1.877218821},
  {id: 8141, weight: 1.858658504},
  {id: 8139, weight: 1.837504368},
  {id: 8135, weight: 1.757311365},
  {id: 8129, weight: 1.820477882},
  {id: 8136, weight: 1.812586274},
  {id: 8150, weight: 1.797104186},
  {id: 8144, weight: 1.770340461},
  {id: 8110, weight: 1.649654877},
  {id: 8128, weight: 1.565844045},
  {id: 8118, weight: 1.510944486},
  {id: 8143, weight: 1.37610047},
  {id: 8127, weight: 1.371058655},
  {id: 8140, weight: 1.074920908},
].map((item) => {
  item.weight = Math.floor(item.weight ** 3);
  return item;
});

function getRandomIndexWithWeight(array) {
  if (array.isEmpty()) {
    return -1;
  }

  const sum = array.reduce((t, item) => t + item.weight, 0);
  let r = Math.floor(Math.random() * (sum + 1));

  return array.findIndex((item) => {
    r -= item.weight;
    return r <= 0;
  });
}

export default {
  getGroups: function() {
    return getGroups();
  },
  getCreatives: function() {
    const sortedItems = [];

    const tree = promptTemplates.map((promptItem) => {
      return {
        ...promptItem,
        positions: positionsTemplatesPairs.shuffle(),
      };
    });

    const usedPromptsInRound = [];
    const _foursomeFaces = [7088, 7088, 7088, 6632];
    let foursomeFaces = _foursomeFaces.slice().shuffle();
    while (tree.isNotEmpty()) {
      if (usedPromptsInRound.length - promptTemplates.length === 0) {
        usedPromptsInRound.length = 0;
      }

      const promptIndex = getRandomIndexWithWeight(tree);
      const promptItem = tree[promptIndex];

      if (usedPromptsInRound.includes(promptItem.id)) {
        continue;
      }

      if (promptItem.positions.isEmpty()) {
        tree.splice(promptIndex, 1);
        continue;
      }

      if (foursomeFaces.isEmpty()) {
        foursomeFaces = _foursomeFaces.slice().shuffle();
      }

      const faceId = foursomeFaces.shift();
      const positionsIndex = promptItem.positions.randomIndex();
      const positionsItem = promptItem.positions.splice(positionsIndex, 1).first();

      usedPromptsInRound.push(promptItem.id);

      sortedItems.push({
        promptId: promptItem.id,
        weight: promptItem.weight,
        faceId: faceId,
        canvasId: positionsItem.canvasId,
        maskId: positionsItem.maskId,
        gender: positionsItem.gender,
      });
    }

    return sortedItems.map((item) => {
      const combo = new CreativeConfig(
        groups.g1,
        `${item.faceId}_[${item.canvasId}_${item.maskId}]_${item.promptId}`,
        handlersNames.COMBO
      );

      const isManFirst = item.gender.indexOf("man") === 0;
      const imagesOrder = [
        {src: "#0"},
        {src: "#1"}
      ];

      combo.setExtra("t_prompt_id", item.promptId);
      combo.setExtra("t_face_id", item.faceId);
      combo.setExtra("t_canvas_id", item.canvasId);
      combo.setExtra("t_mask_id", item.maskId);
      combo.setExtra("t_gender", item.gender);

      combo.setExtra(Creative.EXTRA_COMBO_STEPS, [
        {id: item.faceId, images: [{src: "@0"}], async: true},
        {id: item.faceId, images: [{src: "@1"}]},
        {
          id: item.promptId,
          images: isManFirst ? imagesOrder : imagesOrder.reverse(),
          templateParams: {
            gender: item.gender,
            canvas_template_name: item.canvasId,
            mask_template_name: item.maskId,
          },
          setAsFile: "raw",
        },
      ]);

      return combo;
    });
  },
};
