import React from 'react';
import routes from "../routes";
import * as webviewUtils from "../utils/webview";
import AppContext from "../contexts/AppContext";
import Creative from "../photolab/Creative";
import processingManager from "../photolab/ProcessingManager";
import {generatePath} from "react-router";
import i18n from "../i18n";
import {logEvent, userEvents} from "../utils/log";
import {debounce} from "../utils/etc";
import CreativeView from "./CreativeView";
import {webviewAnalyticsEvent} from "../utils/webview";
import clientStorage from "../utils/client-storage";
import SubscribeBannerView from "./SubscribeBannerView";
import PurchaseBannerView from "./PurchaseBannerView";
import {getInAppSku} from "../utils/inapp";
import inappsHelper from "../helpers/inapps.helper";
import {saveAs} from "file-saver";

const viewMode = {
  grid: "grid",
  list: "list",
};

export default class ResultPage extends React.Component {

  state = {
    isReady: false,
    updatedAt: 0,
    creativesViewMode: viewMode.grid,
    subscribeViewIsShown: false,
    subscribeViewIsAnimated: false,
  };

  componentDidMount() {
    this.scrollToBannerIsScrolled = false;

    window.webviewEventsListeners.tabSelected.subscribe((millis) => {
      if (millis > 0) {
        debounce("ResultPage.handleWebviewTabSelected", 300, () => {
          logEvent(userEvents.PAGE_RESULT);
          webviewAnalyticsEvent("result_shown", [
            "",
            "result",
            clientStorage.getProcessedPhotosAmount()
          ]);
        });
      }
    }, true);

    if (window.clientConfig.isWeb) {
      logEvent(userEvents.PAGE_RESULT);
    }

    if (window.clientConfig.isWebview) {
      webviewUtils.webviewCheckInstalledApps((apps) => {
        this.context.setInstalledApps(apps);
      });
    }

    processingManager.addOnProcessingChangeHandler(this.handleProcessingChanged);

    if (processingManager.processing === null) {
      const restoredProcessing = processingManager.restore();

      if (restoredProcessing) {
        try {
          processingManager.start(restoredProcessing);
          this.setState({isReady: true});
        } catch (err) {
          console.error(err);
          this.props.history.replace(generatePath(routes.INDEX));
        }
      } else {
        this.props.history.replace(generatePath(routes.INDEX));
      }
    } else {
      this.setState({isReady: true});
      this.handleProcessingChanged();
    }
  }

  componentWillUnmount() {
    clearTimeout(this.scrollToBannerTimer);
    clearTimeout(this.subscribeViewIsAnimatedTimer);

    processingManager.removeOnProcessingChangeHandler(this.handleProcessingChanged);
  }

  getPurchase = () => {
    const sku = getInAppSku();
    return this.context.purchases
      .filter((p) => p.product_id === sku)
      .sort((a, b) => b.available - a.available)
      .first();
  };

  isCanStartAnotherGo = () => {
    if (window.clientConfig.features.isNonFree) {
      if (window.clientConfig.features.isSubscribeModel) {
        return window.clientConfig.isPro;
      } else if (window.clientConfig.features.isPurchaseModel) {
        const purchase = this.getPurchase();
        return purchase && purchase.available > 0;
      }
    }

    return true;
  };

  handleProcessingChanged = () => {
    this.context.hideLoader();

    const startedCreatives = processingManager.processing.getStartedCreatives();
    const nonFreeCreatives = startedCreatives.filter((c) => {
      return c.getExtra(Creative.EXTRA_IS_NON_FREE, false) && !c.hasExtra(Creative.EXTRA_IS_PAID);
    });

    if (nonFreeCreatives.isNotEmpty()) {
      if (window.clientConfig.features.isSubscribeModel) {
        if (!window.clientConfig.isPro) {
          this.setState({subscribeViewIsShown: true}, this.scrollToBanner);
        }
      } else if (window.clientConfig.features.isPurchaseModel) {
        const purchase = this.getPurchase();
        if (purchase) {
          inappsHelper.consume(purchase.id, window.clientConfig.features.creativesBatchSize).then((res) => {
            this.context.updatePurchase(res.data);
          });

          nonFreeCreatives.forEach((nonFreeCreative) => {
            nonFreeCreative.setExtra(Creative.EXTRA_IS_PAID, true);
          });

          processingManager.update();
        } else {
          this.setState({subscribeViewIsShown: true}, this.scrollToBanner);
        }
      }
    }

    this.setState({
      updatedAt: Date.now(),
    });
  };

  handleRefreshButtonClick = () => {
    clearTimeout(this.subscribeViewIsAnimatedTimer);
    this.setState({
      subscribeViewIsAnimated: true
    }, () => {
      this.subscribeViewIsAnimatedTimer = setTimeout(() => {
        this.setState({
          subscribeViewIsAnimated: false
        });
      }, 1000);
    });

    webviewAnalyticsEvent("button_tapped", ["another_go", "result"]);

    const startedCreatives = processingManager.processing.getStartedCreatives();
    const nonFreeStartedCreatives = startedCreatives.filter((c) => c.getExtra(Creative.EXTRA_IS_NON_FREE, false));
    const nextCreatives = processingManager.processing.getNextCreatives(window.clientConfig.features.creativesBatchSize);
    const nonFreeNextCreatives = nextCreatives.filter((c) => c.getExtra(Creative.EXTRA_IS_NON_FREE, false));

    if (nextCreatives.isEmpty()) {
      return;
    }

    let isPaid = true;

    if (nonFreeNextCreatives.isNotEmpty() && !this.isCanStartAnotherGo()) {
      this.setState({subscribeViewIsShown: true}, this.scrollToBanner);

      isPaid = false;

      if (nonFreeStartedCreatives.isNotEmpty()) {
        // уже генерили платную пачку под баннер
        return;
      }
    }

    if (window.clientConfig.features.isPurchaseModel) {
      if (nonFreeNextCreatives.isNotEmpty()) {
        const purchase = this.getPurchase();
        if (purchase) {
          inappsHelper.consume(purchase.id, window.clientConfig.features.creativesBatchSize).then((res) => {
            this.context.updatePurchase(res.data);
          });
        }
      }
    }

    nextCreatives.slice(0, window.clientConfig.features.creativesBatchSize).forEach((nextCreative, index) => {
      if (isPaid) {
        nextCreative.setExtra(Creative.EXTRA_IS_PAID, true);
      }

      nextCreative.removeExtra(Creative.EXTRA_KEEP_PENDING);

      webviewAnalyticsEvent("generation_start", [
        "",
        "result",
        clientStorage.getProcessedPhotosAmount(),
        "",
        "",
        startedCreatives.length + index,
      ], {
        wt_couple_ai: {
          "face": nextCreative.getExtra("t_face_id"),
          "gender": nextCreative.getExtra("t_gender"),
          "canvas": nextCreative.getExtra("t_canvas_id"),
          "mask": nextCreative.getExtra("t_mask_id"),
          "tpl": nextCreative.getExtra("t_prompt_id"),
        },
      });
    });

    processingManager.processing.setExtra("batches_amount", processingManager.processing.getExtra("batches_amount", 0) + 1);
    processingManager.update();

    this.handleProcessingChanged();
  };

  handleBackButtonClick = () => {
    this.props.history.replace(routes.UPLOAD);
  };

  handleChangePhotoButtonClick = () => {
    webviewAnalyticsEvent("button_tapped", ["change_photo", "result"]);
    this.props.history.push(routes.UPLOAD);
  };

  scrollToBanner = () => {
    clearTimeout(this.scrollToBannerTimer);
    if (this.scrollToBannerIsScrolled) {
      return;
    }

    this.scrollToBannerTimer = setTimeout(() => {
      this.scrollToBannerIsScrolled = true;

      if (this.bannerContainerRef) {
        this.bannerContainerRef.scrollIntoView({
          block: "end",
          behavior: "smooth",
        });
      }
    }, 500);
  };

  startDownloadImageUrl = (imageUrl) => {
    if (window.clientConfig.isWebview) {
      this.context.setSharePromotionVisible(true, 0, imageUrl);
      webviewUtils.webviewShare({
        providers: "[0]",
        imageUrl: encodeURIComponent(imageUrl),
        autosave: 1,
      });
    } else {
      const fileName = imageUrl.substring(imageUrl.lastIndexOf("/") + 1);
      saveAs(imageUrl, fileName);
    }
  };

  startShareImageUrl = (imageUrl) => {
    const providers = [0];
    const installedApps = this.context.installedApps || {};

    if (window.clientConfig.isWebviewIOS) {
      providers.push(11, 1, 2, 3, 5, 6);
    } else if (window.clientConfig.isWebviewAndroid) {
      installedApps.snapchat && providers.push(11);
      installedApps.instagram && providers.push(1);
      installedApps.facebook && providers.push(2);
      installedApps.whatsapp && providers.push(3);
      installedApps.facebookm && providers.push(5);
      installedApps.telegram && providers.push(6);
    }

    webviewUtils.webviewShare({
      providers: "[" + providers.join(",") + "]",
      imageUrl: encodeURIComponent(imageUrl),
      autosave: 0,
    });
  };

  render() {
    if (!this.context.loader.isHidden || !this.state.isReady) {
      return <React.Fragment />;
    }

    const hasActiveProcessings = processingManager.processing.getInProcessingCreatives().isNotEmpty();

    const creatives = processingManager.processing.creatives
      .filter((creative) => creative.hasExtra(Creative.EXTRA_STARTED_AT))
      .sort((a, b) => a.getExtra(Creative.EXTRA_STARTED_AT) - b.getExtra(Creative.EXTRA_STARTED_AT));

    const hasMoreCreatives = processingManager.processing
      .getNonStartedCreatives()
      .isNotEmpty();

    const proCreativesItems = [];
    const creativesItems = creatives.slice();

    const classNames = ["result-page"];

    if (this.state.subscribeViewIsShown) {
      proCreativesItems.unshift(
        ...creativesItems.splice(-window.clientConfig.features.creativesBatchSize)
      );

      classNames.push("existent");
    }

    if (this.state.subscribeViewIsAnimated) {
      classNames.push("result-page--subscribe-view-animated");
    }

    return <main className={classNames.join(" ")}>
      <div className="container">
        <div className="header">
          <button className="btn-back" onClick={this.handleBackButtonClick}>
            <svg viewBox="0 0 25 16" fill="none">
              <path d="M.293 7.293a1 1 0 0 0 0 1.414l6.364 6.364a1 1 0 0 0 1.414-1.414L2.414 8l5.657-5.657A1 1 0 0 0 6.657.93L.293 7.293zM1 9h24V7H1v2z" fill="#fff"/>
            </svg>
          </button>
          <p>{i18n.t("result_title")}</p>
        </div>
        
        <div
          className="result-notice"
          hidden={!this.context.sharePromotionIsShown}
          onClick={() => this.startShareImageUrl(this.context.sharePromotionImageUrl)}>
          <p>
            <span dangerouslySetInnerHTML={{__html: i18n.t("result_notice")}} />
            <SvgArrowIcon />
          </p>
        </div>
        
        <div className="sort-container">
          <button 
            className={`btn-sort ${this.state.creativesViewMode === viewMode.grid ? "active" : ""}`}
            onClick={() => {this.setState({creativesViewMode: viewMode.grid})}}>
            <IconSortRow />
          </button>
          <button 
            className={`btn-sort ${this.state.creativesViewMode === viewMode.list ? "active" : ""}`}
            onClick={() => {this.setState({creativesViewMode: viewMode.list})}}>
            <IconSortColumn />
          </button>
        </div>
        <div className={`results-grid ${this.state.creativesViewMode === viewMode.list ? "results-grid-list" : ""}`}>
          {creativesItems.map((creative, position) => <CreativeView
            key={creative.id}
            creative={creative}
            position={position}
            downloadHandler={this.startDownloadImageUrl}
            shareHandler={this.startShareImageUrl}
          />)}
        </div>
        <div className="btns-container">
          <button className="btn-change" onClick={this.handleChangePhotoButtonClick}>
            {i18n.t("change_photo")}
          </button>
          <button
            className={"btn-refresh " + ((hasActiveProcessings && !this.state.subscribeViewIsShown) ? "btn-refresh--roll" : "")}
            hidden={!hasMoreCreatives}
            onClick={this.handleRefreshButtonClick}>
            <IconRefresh />
            {i18n.t("another_go")}
          </button>
        </div>
        {this.state.subscribeViewIsShown && <React.Fragment>
          <div className={`results-grid results-grid-2`} ref={(ref) => this.bannerContainerRef = ref}>
            {proCreativesItems.map((creative, position) => <CreativeView
              key={creative.id}
              creative={creative}
              position={position + creativesItems.length}
              isOverlayed
              isWaiting={creative.isFailed}
            />)}
            {window.clientConfig.features.isSubscribeModel && <SubscribeBannerView />}
            {window.clientConfig.features.isPurchaseModel && <PurchaseBannerView />}
          </div>
        </React.Fragment>}
      </div>
    </main>;
  }
}

function IconRefresh() {
  return <svg viewBox="0 0 19 18">
    <path d="M5 15.5a.5.5 0 0 0 .5-.5v-4.5a.5.5 0 0 0-1 0v4h-4a.5.5 0 0 0 0 1H5zM17.5 9a7.5 7.5 0 0 1-7.5 7.5v1A8.5 8.5 0 0 0 18.5 9h-1zM10 1.5A7.5 7.5 0 0 1 17.5 9h1A8.5 8.5 0 0 0 10 .5v1zm0-1C5.309.5 1.5 4.22 1.5 9h1c0-4.22 3.355-7.5 7.5-7.5v-1zM1.5 9c0 2.352.656 3.838 1.42 4.809.377.48.77.821 1.095 1.072.16.123.31.227.42.307.122.089.182.136.211.166l.708-.708a2.827 2.827 0 0 0-.334-.268c-.121-.088-.25-.179-.395-.29a5.086 5.086 0 0 1-.92-.897C3.095 12.412 2.5 11.148 2.5 9h-1z" />
  </svg>;
}

function IconSortColumn() {
  return <svg viewBox="0 0 12 12" fill="none">
    <rect width="12" height="12" rx="2" />
</svg>
}

function IconSortRow() {
  return <svg viewBox="0 0 16 16" fill="none" >
    <rect width="7" height="7" rx="1" />
    <rect x="9" width="7" height="7" rx="1" />
    <rect y="9" width="7" height="7" rx="1" />
    <rect x="9" y="9" width="7" height="7" rx="1" />
  </svg>;
}

function SvgArrowIcon() {
  return <svg viewBox="0 0 24 24" fill="none">
    <path d="M5 12h14M12 5l7 7-7 7" stroke="#F8D34F" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>;
}

ResultPage.contextType = AppContext;